.Home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14pt;
  color: white;

  @media (prefers-reduced-motion: no-preference) {
    .Cover-image {
      opacity: 0;
      animation: Element-appear 0.75s ease-out forwards;
      animation-delay: 0.75s;
      clip-path: polygon(0% 0%, 100% 0, 100% 85%, 50% 100%, 0 85%);
      z-index: 2;
    }
  }
}



.Home-content {
  display: flex;
  opacity: 0;
  animation: Element-appear 1.5s ease-out forwards;
  animation-delay: 1s;

  .Gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .Map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
   
    .Map-wrapper {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
}

.Cover-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
