.Section-with-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 12px 24px;

  .Section-with-form-content {
    width: 100%;
    height: 100%;
  }
}