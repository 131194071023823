.Section-with-bullet-points-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;

  .Section-with-bullet-points-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 24px;
  }
}