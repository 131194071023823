:root{
  --primary: #326273;
  --primary-light: #5C9ead;
  --secondary: #b3965a;
}

body {
  margin: 0;
  font-family: 'Delphin',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .Section {
    will-change: transform, opacity;
    transform: translateY(75px);
    opacity: 0;
    transition: 1s opacity ease, 1s transform ease;
  }

  .Section.Active {
    transform: translateY(0px);
    opacity: 1;
  }

  h3 {
    color: var(--primary-light) !important;
  }

  h4 {
    color: var(--primary-light) !important;
  }

  h5 {
    color: var(--primary-light) !important;
  }

  h6 {
    color: var(--primary-light) !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes Section-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Element-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
