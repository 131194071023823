.Section-with-icons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  .Section-with-icons-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    gap: 18px;

    .Icon-box {
      flex-basis: 30%;
      min-width: 240px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.Vertical {
        flex-direction: row !important;
      }
      .Icon-container {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color:  var(--secondary);

        & svg {
          font-size: 48pt;
        }
      }

      .Icon-underline {
        margin: 8px;
        width: 0px;
        height: 3px;
        background-color: var(--secondary);
        transition: 0.5s width ease;
      }

      &:hover {
        .Icon-underline {
          width: 100px;
        }
      }
    }

  }
}