.Section-with-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .Image-container {
    background-color: #aaaaaa20;
    overflow: hidden;
    
    .Image {
      height: 100%;
      aspect-ratio: initial;
      object-fit: cover;
    }
  }

  .Text-wrapper {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .Text-container {
      padding: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}