.Contact-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14pt;

  .Contact-page-content {
    opacity: 0;
    animation: Element-appear 1.5s ease-out forwards;
    animation-delay: 1s;
  }
}