.Policies-page-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14pt;

  @media (prefers-reduced-motion: no-preference) {
    .Cover-image {
      opacity: 0;
      animation: Element-appear 0.75s ease-out forwards;
      aspect-ratio: 24/9;
      animation-delay: 0.5s;
      clip-path: polygon(0% 0%, 100% 0, 100% 85%, 50% 100%, 0 85%);
      z-index: 2;
    }
  }

  .Policies-content {
    display: flex;
    opacity: 0;
    animation: Element-appear 1.5s ease-out forwards;
    animation-delay: 1s;
  }
}